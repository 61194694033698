import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import GoogleMapReact from "google-map-react"
import ContactForm from "../components/forms/contact"

const GeneralInquiry = () => {
    const [center] = useState({
        lat: 16,
        lng: 35
    })
    const [zoom] = useState(1)

    const mapOptions = {
        disableDefaultUI: true
    }

    const markers = [
        { lat: 55.6761, lng: 12.5683 }, // Copenhagen
        { lat: 28.7041, lng: 77.1025 }, // Delhi
        { lat: 25.2048, lng: 55.2708 }, // Dubai
        { lat: 19.076, lng: 72.8777 }, // Mumbai
        { lat: 1.3521, lng: 103.8198 }, // Singapore

        { lat: 1.3521, lng: 103.8198 }, // Rotterdam
        { lat: 53.551, lng: 9.9937 }, // Hamburg
        { lat: 46.2044, lng: 6.1432 }, // Geneva
        { lat: 51.5072, lng: 0.1276 } // London
    ]

    const renderMarkers = (map, maps) => {
        const mapMarkers = markers.forEach(
            marker =>
                new maps.Marker({
                    position: marker,
                    map
                })
        )
        return mapMarkers
    }

    return (
        <Layout>
            <Seo title="Contact" />
            <div className="contact-map">
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyDHbjASsFo0OsTChaxi9ymgqBibepTAn7E" }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    options={mapOptions}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                ></GoogleMapReact>
            </div>

            <div className="container-md pb-5">
                <div className="row contact-card">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="card-title">Contact us</h1>
                                <p className="card-text">
                                    Please use the below form for general enquries, and we will get back to you as soon as possible.
                                </p>
                                <div className="row mb-3">
                                    <div className="col-12 ">
                                        <ContactForm environment={2000} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default GeneralInquiry
